* {
  box-sizing: border-box;
}

body {
  padding-top: 65px;
  margin: 0;
  font-family: Lora, Georgia, "Times New Roman", Times, serif;
  font-size: 13px;
  line-height: 20px;
  color: #666;
  background-color: #f0f0f0;
}

@media (min-width: 734px) {
  body {
    padding-top: 95px;
  }
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}
a {
  text-decoration: none;
  background-color: transparent;
  color: #336DB8;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
strong {
  font-weight: 700;
}
h1 {
  font-size: 2em;
}
img {
  vertical-align: middle;
}
a:hover {}
a:focus {
  outline: dotted thin;
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: -2px;
}
h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  margin: 0;
}
h2,
h4 {
  font-weight: 700;
}
h2 {
  color: #333;
  font-size: 18px;
  line-height: 24px;
}
h3,
h4 {
  line-height: 20px;
  color: #333;
  font-size: 13px;
}

ul {
  margin: 0;
  padding-left: 20px;
}
li {
  margin-bottom: 5px;
}
li:last-child {
  margin-bottom: 0;
}
